import React from "react";
import "./Section4.css";
import SectionTitle from "../Atoms/SectionTitle";

export default function Section4() {
  const small1 = ["Cutie manuală", "Cutie automată"];
  const big = [
    "Pregătire teoretică",
    "Pregătirea practică (30 ore) – conducerea autovehiculului (carburantul este inclus)",
    "Combustibil",
    "Pregătirea dosarului pentru examinare",
  ];
  const bigDetails = [
    <p>La înscriere se achită un avans de 800 lei</p>,
    <p>Școlarizarea se poate achita în maxim 3 rate.</p>,
    <p>
      Perioada de școlarizare <br />
      &nbsp;&nbsp; Minim 4 săptămâni <br />
      &nbsp;&nbsp; Maxim 6 luni
    </p>,
  ];
  const small2 = ["Ședință pregătire practică suplimentară"];

  return (
    <div className="section4-container">
      <SectionTitle title="Prețuri" side={true} />
      <div className="section4-container-core">
        <SmalItem title="Școlarizare" items={small1} position={true} />
        <BigItem title="2600 RON" items={big} details={bigDetails} />
        <SmalItem title="150 RON" items={small2} position={false} />
      </div>
    </div>
  );
}

const SmalItem = ({ title, items, position }) => {
  return (
    <div
      className={`${
        position == true ? "section4-container-core-small" : "section4-container-core-small-revert"
      }`}
      id="preturi"
    >
      <div className="section4-container-core-small-core">
        <h4>{title}</h4>
        <div className="section4-container-core-small-core-spread">
          {items.map((el) => {
            return (
              <div className="section4-container-core-small-core-items">
                <img src="/media/icons/checked.png" />
                <p>{el}</p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const BigItem = ({ items, title, details }) => {
  return (
    <div className="section4-container-core-big">
      <h4>{title}</h4>
      <div className="section4-container-core-big-items">
        {items.map((el) => {
          return (
            <div className="section4-container-core-big-items-row">
              <img src="/media/icons/checked.png" />
              <p>{el}</p>
            </div>
          );
        })}
      </div>
      <div className="section4-container-core-big-details">
        <p>
          <i>Detalii</i>
        </p>
        <div className="section4-container-core-big-details-items">
          {details.map((el) => {
            return <p>{el}</p>;
          })}
        </div>
      </div>
    </div>
  );
};
