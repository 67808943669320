import React, { useState, useEffect } from "react";
import "./Chestionar.css";
import { useSession } from "../../common-backend/SessionContext";
import { Quiz } from "../../components/Quiz";
import ResultsPageChestionare from "../../components/ResultsPageChestionare";

export default function Chestionar() {
  const { session } = useSession();
  //pentru a stoca chestionarele(id si nume), se seteaza cu fetch-ul din backend
  const [chestionare, setChestionare] = useState([]);
  //pentru a afisa un chestionar dupa id-ul sau luat din state-ul chestionare, se seteaza cu fetch-ul din backend si id-ul din state-ul chestionare
  const [viewChestionar, setViewChestionar] = useState(null);
  const [selectedChestionarName, setSelectedChestionarName] = useState(null);
  const [dateAndTime, setDateAndTime] = useState(null);
  const [quizTime, setQuizTime] = useState(null);
  const [chestionareRezolvate, setChestionareRezolvate] = useState([]);
  const [selectedChestionar, setSelectedChestionar] = useState(null);

  function checkPassingScore(data) {
    const totalAnswers = data.answers.length;
    const correctAnswers = data.answers.filter((answer) => answer.isCorrect).length;

    const score = (correctAnswers / totalAnswers) * 100;

    return score >= 91.7;
  }

  function getPassingScore(data) {
    const totalAnswers = data.answers.length;
    const correctAnswers = data.answers.filter((answer) => answer.isCorrect).length;

    return `${correctAnswers}/${totalAnswers}`;
  }

  function getUserPicture() {
    return session.userInfo.profile_picture;
  }

  const handleBackButtonClick = () => {
    setSelectedChestionar(null);
  };

  const questionsObject =
    selectedChestionar && selectedChestionar.answers
      ? { questions: selectedChestionar.answers.map((answer) => answer.question) }
      : null;

  const handleQuestionnaireSelection = (index) => {
    // Set the current date and time
    setDateAndTime(formatDateAndTime(new Date()));
    //fetch chestionarul cu id-ul chestionare[index].id
    setSelectedChestionarName(chestionare[index].nume);
    fetchChestionarByID(chestionare[index].id);
  };

  const formatDateAndTime = (dateAndTime) => {
    const formattedDate = dateAndTime.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
    const formattedTime = dateAndTime.toLocaleTimeString("en-GB", {
      hour: "2-digit",
      minute: "2-digit",
    });
    const formattedDateTime = `${formattedDate} - ${formattedTime}`;
    return formattedDateTime;
  };

  const renderQuestionnaireSelection = () => {
    return (
      <button onClick={handleRandomQuestionnaireSelection} className="start-button">
        <span className="start-icon">▶</span> Incepe un chestionar
      </button>
    );
  };

  const handleRandomQuestionnaireSelection = () => {
    const randomIndex = Math.floor(Math.random() * chestionare.length);
    handleQuestionnaireSelection(randomIndex);
  };

  const fetchChestionare = async () => {
    fetch(`https://www.autojuv.magnusbooking.ro/api/getChestionare`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${session.session.session.access_token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          console.log(data.error.message);
        } else {
          setChestionare(data.chestionare);
        }
      });
  };
  const fetchChestionarByID = async (id) => {
    fetch(`https://www.autojuv.magnusbooking.ro/api/getChestionareById?id=${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${session.session.session.access_token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          console.log(data.error.message);
        } else {
          // Shuffle the questions
          data.chestionar[0].intrebariSiRaspunsuri.questions.sort(() => Math.random() - 0.5);

          // Shuffle the answer options for each question
          data.chestionar[0].intrebariSiRaspunsuri.questions.forEach((question) => {
            question.answerOptions.sort(() => Math.random() - 0.5);
          });
          setViewChestionar(data.chestionar[0].intrebariSiRaspunsuri);
          setQuizTime(data.chestionar[0].timp);
          setSelectedChestionarName(data.chestionar[0].nume);
          localStorage.setItem(
            "ActiveQuiz",
            JSON.stringify(data.chestionar[0].intrebariSiRaspunsuri)
          );
        }
      });
  };

  useEffect(() => {
    fetchChestionare();
    fetchChestionareRezolvate();
  }, []);

  useEffect(() => {
    const isQuizActive = JSON.parse(localStorage.getItem("isQuizActive"));
    const ActiveQuiz = JSON.parse(localStorage.getItem("ActiveQuiz"));

    if (!isQuizActive) {
      setViewChestionar(null);
      localStorage.removeItem("ActiveQuiz");
    } else {
      setViewChestionar(ActiveQuiz);
    }
  }, []);

  const fetchChestionareRezolvate = async () => {
    fetch(
      `https://www.autojuv.magnusbooking.ro/api/getChestionarResults?userId=${session.session.user.id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${session.session.session.access_token}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data && data.error) {
          console.log("Error fetching users: " + data.error.message);
        } else {
          if (data !== null) {
            setChestionareRezolvate(data.chestionare);
          }
        }
      });
  };

  return (
    <div className="chestionar-container">
      {selectedChestionar ? (
        <>
          <ResultsPageChestionare
            score={selectedChestionar.answers.reduce(
              (total, answer) => total + (answer.isCorrect ? 1 : 0),
              0
            )}
            quizData={questionsObject}
            answers={selectedChestionar.answers}
            handleBackButtonClick={handleBackButtonClick}
            nameChestionar={selectedChestionarName}
            chestionarDate={selectedChestionar.dateAndTime}
          />
        </>
      ) : (
        <>
          {viewChestionar !== null ? (
            <>
              <div className="custom-quiz">
                <Quiz
                  quizData={viewChestionar}
                  setViewChestionar={setViewChestionar}
                  dateAndTime={dateAndTime}
                  selectedChestionarName={selectedChestionarName}
                  quizTime={quizTime}
                  selectedChestionar={selectedChestionar}
                />
              </div>
            </>
          ) : (
            <>
              <div className="chestionar-selection">{renderQuestionnaireSelection()}</div>
              <p  className="solved-chestionar-title">
                  Chestionare rezolvate:
              </p>

              <div className="solved-chestionar-selection-list">
                {chestionareRezolvate &&
                  chestionareRezolvate.map((el, index) => {
                    return (
                      <div
                        className="rezultate-container-items-el"
                        onClick={() => {
                          setSelectedChestionar(el);
                          setSelectedChestionarName(el.chestionarName);
                        }}
                        key={index}
                      >
                        <div className="rezultate-container-items-core">
                          <p
                            style={{
                              padding: "5px",
                              backgroundColor: "#29CC39",
                              textAlign: "center",
                              color: "white",
                              fontWeight: "bold",
                              borderRadius: "10px",
                              width: "200px",
                            }}
                          >
                            {el.dateAndTime}
                            <br></br>
                            {el.hour}
                          </p>
                        </div>
                        <div className="rezultate-container-items-core">
                          <p
                            style={{
                              fontWeight: "bold",
                            }}
                          >
                            {el.chestionarName}
                          </p>
                        </div>
                        <div className="rezultate-container-items-core">
                          <p
                            style={{
                              fontWeight: "bold",
                            }}
                          >
                            {el.userName}
                          </p>
                          <img src={getUserPicture(el)} />
                        </div>
                        <div className="rezultate-container-items-core">
                          <p
                            style={{
                              padding: "5px",
                              backgroundColor: `${
                                checkPassingScore(el) == true ? "#29CC39" : "red"
                              }`,
                              textAlign: "center",
                              color: "white",
                              fontWeight: "bold",
                              borderRadius: "10px",
                              width: "200px",
                            }}
                          >
                            {getPassingScore(el)}
                          </p>
                        </div>
                        <div
                          className="rezultate-container-items-core"
                          style={{ flexDirection: "column" }}
                        >
                          <img
                            src={
                              checkPassingScore(el) == true
                                ? "/media/passed.png"
                                : "/media/failed.png"
                            }
                            style={{ marginBottom: "5px" }}
                          />
                          <p style={{ fontWeight: "bold" }}>
                            {checkPassingScore(el) == true ? "Admis" : "Respins"}
                          </p>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
}
