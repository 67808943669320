import React, { useRef, useState, useEffect } from "react";
import Modal from "react-modal";
import { useSession } from "../common-backend/SessionContext";
import "../pages/Administrator/Chestionar.css";
import ResultsPageChestionare from "./ResultsPageChestionare";

export const Quiz = ({
  quizData,
  setViewChestionar,
  dateAndTime,
  selectedChestionarName,
  quizTime,
  selectedChestionar,
}) => {
  const isFirstRender = useRef(true);
  const indexAlphabet = ["A", "B", "C", "D", "E", "F", "G", "H", "I"];

  const { session } = useSession();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [skippedQuestions, setSkippedQuestions] = useState([]);

  const handleConfirmExit = () => {
    // Send the answers to the backend
    if (!showScore) {
      sendChestionarResult({
        answers: answers,
        dateAndTime: JSON.parse(localStorage.getItem("dateAndTime")),
        userName: session.userInfo.user_name,
        userId: session.session.user.id,
        chestionarName: JSON.parse(localStorage.getItem("selectedChestionarName")),
      });
    }
    setModalIsOpen(false);
    // Reset state variables
    setCurrentQuestion(0);
    setShowScore(false);
    setScore(0);
    setNegativeScore(0);
    setAnswers([]);
    setSelectedAnswer(null);
    setSelectedAnswers([]);
    setTimer(30);
    // Clear localStorage
    localStorage.removeItem("currentQuestion");
    localStorage.removeItem("showScore");
    localStorage.removeItem("score");
    localStorage.removeItem("negativeScore");
    localStorage.removeItem("answers");
    localStorage.removeItem("selectedAnswer");
    localStorage.removeItem("selectedAnswers");
    localStorage.removeItem("timer");
    localStorage.removeItem("quizTime");
    localStorage.removeItem("dateAndTime");
    localStorage.removeItem("selectedChestionarName");
    localStorage.setItem("isQuizActive", JSON.stringify(false));

    setViewChestionar(null);
  };

  const [currentQuestion, setCurrentQuestion] = useState(
    JSON.parse(localStorage.getItem("currentQuestion")) || 0
  );
  const [showScore, setShowScore] = useState(
    JSON.parse(localStorage.getItem("showScore")) || false
  );
  const [score, setScore] = useState(JSON.parse(localStorage.getItem("score")) || 0);
  const [negativeScore, setNegativeScore] = useState(
    JSON.parse(localStorage.getItem("negativeScore")) || 0
  );
  const [answers, setAnswers] = useState(JSON.parse(localStorage.getItem("answers")) || []);
  const [selectedAnswer, setSelectedAnswer] = useState(
    JSON.parse(localStorage.getItem("selectedAnswer")) || null
  );
  const [selectedAnswers, setSelectedAnswers] = useState(
    JSON.parse(localStorage.getItem("selectedAnswers")) || []
  );
  const timerFromStorage = localStorage.getItem("timer");
  const quizTimeFromStorage = localStorage.getItem("quizTime");

  const [timer, setTimer] = useState(
    quizTime ||
      (timerFromStorage ? JSON.parse(timerFromStorage) : null) ||
      (quizTimeFromStorage ? JSON.parse(quizTimeFromStorage) : null) ||
      900
  );

  useEffect(() => {
    localStorage.setItem("currentQuestion", JSON.stringify(currentQuestion));
    localStorage.setItem("showScore", JSON.stringify(showScore));
    localStorage.setItem("score", JSON.stringify(score));
    localStorage.setItem("negativeScore", JSON.stringify(negativeScore));
    localStorage.setItem("answers", JSON.stringify(answers));
    localStorage.setItem("selectedAnswer", JSON.stringify(selectedAnswer));
    localStorage.setItem("selectedAnswers", JSON.stringify(selectedAnswers));
    localStorage.setItem("timer", JSON.stringify(timer));
  }, [
    currentQuestion,
    showScore,
    score,
    negativeScore,
    answers,
    selectedAnswer,
    selectedAnswers,
    timer,
  ]);

  useEffect(() => {
    if (dateAndTime) {
      localStorage.setItem("dateAndTime", JSON.stringify(dateAndTime));
    }
    if (selectedChestionarName) {
      localStorage.setItem("selectedChestionarName", JSON.stringify(selectedChestionarName));
    }
    if (quizTime) {
      localStorage.setItem("quizTime", JSON.stringify(quizTime));
    }
    localStorage.setItem("isQuizActive", JSON.stringify(true));
  }, []);

  useEffect(() => {
    const countdown = setInterval(() => {
      setTimer((prevTimer) => prevTimer - 1);
    }, 1000);

    if (timer === 0 || showScore) {
      clearInterval(countdown);
      setShowScore(true);
      // If time runs out and not all questions were answered, update the answers state with the unanswered questions and their correct answers
      if (timer === 0 && answers.length < quizData.questions.length) {
        const unansweredQuestions = quizData.questions.slice(answers.length);
        setAnswers((prevAnswers) => [
          ...prevAnswers,
          ...unansweredQuestions.map((question) => ({
            question: question,
            userAnswers: [],
            isCorrect: false,
          })),
        ]);
      }
    }
    return () => clearInterval(countdown);
  }, [timer, showScore]);

  const handleAnswerOptionClick = (index, isCorrect) => {
    const alreadySelected = selectedAnswers.findIndex((answer) => answer.index === index);
    if (alreadySelected !== -1) {
      setSelectedAnswers((prev) => prev.filter((answer) => answer.index !== index));
    } else {
      setSelectedAnswers((prev) => [...prev, { index, isCorrect }]);
    }
  };

  const handleNextButtonClick = () => {
    const correctAnswersInQuestion = quizData.questions[currentQuestion].answerOptions.filter(
      (option) => option.isCorrect
    );
    const correctAnswersSelected = selectedAnswers.filter((answer) => answer.isCorrect);
    const incorrectAnswersSelected = selectedAnswers.filter((answer) => !answer.isCorrect);

    if (
      correctAnswersInQuestion.length === correctAnswersSelected.length &&
      incorrectAnswersSelected.length === 0
    ) {
      setScore(score + 1);
    } else {
      setNegativeScore(negativeScore + 1);
    }

    if (quizData.questions[currentQuestion]) {
      setAnswers((prev) => [
        ...prev,
        {
          question: quizData.questions[currentQuestion],
          userAnswers: selectedAnswers,
          isCorrect:
            correctAnswersInQuestion.length === correctAnswersSelected.length &&
            incorrectAnswersSelected.length === 0,
        },
      ]);
    }

    const nextQuestion = currentQuestion + 1;
    if (nextQuestion < quizData.questions.length) {
      setCurrentQuestion(nextQuestion);
    } else {
      setShowScore(true);
    }

    setSelectedAnswers([]);
  };

  function answerLater(skippedQuestion) {
    //deselect the select options
    setSelectedAnswers([]);
    // Remove the skippedQuestion from the quizData.questions array
    quizData.questions = quizData.questions.filter((question) => question !== skippedQuestion);

    // Add the skippedQuestion to the end of the quizData.questions array
    quizData.questions.push(skippedQuestion);
  }

  const handleBackButtonClick = () => {
    // If not all questions were answered, update the answers state with the unanswered questions and their correct answers
    if (answers.length < quizData.questions.length) {
      const unansweredQuestions = quizData.questions.slice(answers.length);
      setAnswers((prevAnswers) => [
        ...prevAnswers,
        ...unansweredQuestions.map((question) => ({
          question: question,
          userAnswers: [],
          isCorrect: false,
        })),
      ]);
    }

    setModalIsOpen(true);
  };

  const sendChestionarResult = async (resultData) => {
    fetch(`https://www.autojuv.magnusbooking.ro/api/sendChestionarResult`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${session.session.session.access_token}`,
      },
      body: JSON.stringify(resultData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          console.log(data.error.message);
        } else {
          console.log(data);
        }
      });
  };

  useEffect(() => {
    if (isFirstRender.current) {
      console.log("first render");
      isFirstRender.current = false; // change the flag after first render
    } else if (showScore) {
      console.log("show score");
      sendChestionarResult({
        answers: answers,
        dateAndTime: JSON.parse(localStorage.getItem("dateAndTime")),
        userName: session.userInfo.user_name,
        userId: session.session.user.id,
        chestionarName: JSON.parse(localStorage.getItem("selectedChestionarName")),
      });
    }
  }, [showScore]);

  return (
    <div
      style={{
        textAlign: "center",
        height: "auto",
        overflowY: "scroll",
      }}
    >
      {showScore ? (
        <>
          <ResultsPageChestionare
            score={score}
            quizData={quizData}
            answers={answers}
            handleBackButtonClick={handleBackButtonClick}
            userName={session.userInfo.user_name}
            nameChestionar={selectedChestionarName ? selectedChestionarName : JSON.parse(localStorage.getItem("selectedChestionarName"))}
            chestionarDate={new Date().toLocaleDateString('en-GB')}
          />
        </>
      ) : (
        <>
          <div className="chestionar-container">
            <div className="chestionar-container-head">
              <div className="chestionar-container-head-col">
                <div className="chestionar-container-head-col-top">
                  <p>{quizData.questions.length}</p>
                </div>
                <div className="chestionar-container-head-col-bot">
                  <p>Întrebări inițiale</p>
                </div>
              </div>
              <div className="chestionar-container-head-col">
                <div className="chestionar-container-head-col-top">
                  <p>{quizData.questions.length - currentQuestion}</p>
                </div>
                <div className="chestionar-container-head-col-bot">
                  <p>Întrebări rămase</p>
                </div>
              </div>
              <div className="chestionar-container-head-col">
                <div className="chestionar-container-head-col-top">
                  <p>
                    {Math.floor(timer / 60)}:{("0" + (timer % 60)).slice(-2)}
                  </p>
                </div>
                <div className="chestionar-container-head-col-bot">
                  <p>Timp rămas</p>
                </div>
              </div>
              <div className="chestionar-container-head-col">
                <div className="chestionar-container-head-col-top">
                  <p
                    style={{
                      color: `green`,
                    }}
                  >
                    {score}
                  </p>
                </div>
                <div className="chestionar-container-head-col-bot">
                  <p>Întrebări corecte</p>
                </div>
              </div>
              <div className="chestionar-container-head-col-last">
                <div className="chestionar-container-head-col-top">
                  <p
                    style={{
                      color: `red`,
                    }}
                  >
                    {negativeScore}
                  </p>
                </div>
                <div className="chestionar-container-head-col-bot">
                  <p>Întrebări greșite</p>
                </div>
              </div>
            </div>
            <div className="chestionar-container-content">
              {quizData.questions[currentQuestion].questionText === undefined ? null : (
                <div className="chestionar-container-content-top">
                  <p>{quizData.questions[currentQuestion].questionText}</p>
                </div>
              )}
              <div className="chestionar-container-content-values">
                <div
                  style={{
                    display: `${
                      quizData.questions[currentQuestion].answerOptions[0].answerImage
                        ? "flex"
                        : "block"
                    }`,
                    flexWrap: `${
                      quizData.questions[currentQuestion].answerOptions[0].answerImage
                        ? "wrap"
                        : "no-wrap"
                    }`,
                    width: `${
                      quizData.questions[currentQuestion].questionImage === undefined
                        ? "100%"
                        : "60%"
                    }`,
                  }}
                  className="chestionar-container-content-values-ques"
                >
                  {quizData.questions[currentQuestion].answerOptions.map((ansEl, andIndex) => {
                    return (
                      <p
                        key={andIndex}
                        onClick={() => handleAnswerOptionClick(andIndex, ansEl.isCorrect)}
                        style={{
                          marginRight: `${ansEl.answerImage ? "20px" : "0px"}`,
                          width: `${ansEl.answerImage ? "30%" : "100%"}`,
                          backgroundColor: selectedAnswers.some(
                            (answer) => answer.index === andIndex
                          )
                            ? "#007BFF"
                            : "#fff",
                          color: selectedAnswers.some(
                            (answer) => answer.index === andIndex
                          )
                            ? "#FFF"
                            : "black",
                          marginBottom: `${
                            andIndex == quizData.questions[currentQuestion].answerOptions.length - 1
                              ? "0px"
                              : "30px"
                          }`,
                        }}
                      >
                        <span>{indexAlphabet[andIndex]}</span>
                        {ansEl.answerImage && ansEl.answerText ? (
                          <>
                            {ansEl.answerText}
                            <img
                              src={ansEl.answerImage}
                              alt="answer"
                              style={{ width: "80%", objectFit: "contain", borderRadius: "10px" }}
                            />
                          </>
                        ) : ansEl.answerImage ? (
                          <img
                            src={ansEl.answerImage}
                            alt="answer"
                            style={{ width: "80%", objectFit: "contain", borderRadius: "10px" }}
                          />
                        ) : (
                          ansEl.answerText
                        )}
                      </p>
                    );
                  })}
                </div>
                {quizData.questions[currentQuestion].questionImage === undefined ? null : (
                  <div className="chestionar-container-content-values-ques-img">
                    <img
                      src={quizData.questions[currentQuestion].questionImage}
                      alt="Imagine Intrebare"
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="chestionar-container-button">
              <div className="chestionar-container-button-top">
                <button onClick={handleNextButtonClick}>Trimite răspuns</button>
              </div>
              <div className="chestionar-container-button-bot">
                <button
                  onClick={() => answerLater(quizData.questions[currentQuestion])}
                  style={{ background: "none", border: "none" }}
                >
                  <p>
                    <img src="/media/clock.png" alt="later icon" />
                    <span>Răspunde mai târziu</span>
                  </p>
                </button>
              </div>
            </div>
          </div>
        </>
      )}

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        contentLabel="Exit Confirmation"
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.75)", // This is a semi-transparent black background
          },
          content: {
            minWidth: "200px", // This sets the width of the modal
            maxWidth: "400px", // This sets the width of the modal
            height: "200px", // This sets the height of the modal
            margin: "auto", // This centers the modal
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            border: "1px solid #ccc",
            background: "#fff",
            overflow: "auto",
            WebkitOverflowScrolling: "touch",
            borderRadius: "4px",
            outline: "none",
            padding: "20px",
          },
        }}
      >
        <div className="modal-quiz-container">
          <h4>Esti sigur ca vrei sa iesi din chestionar?</h4>
          <button onClick={handleConfirmExit}>Yes</button>
          <button onClick={() => setModalIsOpen(false)}>No</button>
        </div>
      </Modal>
    </div>
  );
};
