import React, { useState, useCallback, useRef, useEffect } from "react";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import "../Landing/Section3.css";

export const photos = [
  {
    src: "https://source.unsplash.com/qDkso9nvCg0/600x799",
    width: 3,
    height: 4,
  },
  {
    src: "https://source.unsplash.com/iecJiKe_RNg/600x799",
    width: 3,
    height: 4,
  },
  {
    src: "https://source.unsplash.com/epcsn8Ed8kY/600x799",
    width: 3,
    height: 4,
  },
  {
    src: "https://source.unsplash.com/NQSWvyVRIJk/800x599",
    width: 4,
    height: 3,
  },
  {
    src: "https://source.unsplash.com/zh7GEuORbUw/600x799",
    width: 3,
    height: 4,
  },
  {
    src: "https://source.unsplash.com/PpOHJezOalU/800x599",
    width: 4,
    height: 3,
  },
  {
    src: "https://source.unsplash.com/I1ASdgphUH4/800x599",
    width: 4,
    height: 3,
  },
];

export default function LighBoxScroller({ images, landscape }) {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  const [visibleImages, setVisibleImages] = useState(10); // Initial number of images to display

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const formatPhotos = (src) => {
    return src.map((el) => {
      return {
        src: el,
        width: landscape === true ? 4 : 3,
        height: landscape === true ? 3 : 4,
      };
    });
  };

  const handleViewMore = () => {
    setVisibleImages(images.length); // Show all images
  };

  const handleViewLess = () => {
    setVisibleImages(10); // Show initial number of images
  };

  return (
    <div>
      <Gallery photos={formatPhotos(images.slice(0, visibleImages))} onClick={openLightbox} />
      {visibleImages < images.length && (
        <div class="view-more-button-container">
          <button class="view-more-button" onClick={handleViewMore}>Vezi mai mult</button>
        </div>
      )}
      {visibleImages === images.length && (
        <div class="view-more-button-container">
          <button class="view-more-button" onClick={handleViewLess}>Vezi mai puțin</button>
        </div>
      )}
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={formatPhotos(images).map((x) => ({
                ...x,
                srcset: x.srcSet,
                caption: x.title,
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </div>
  );
}