import React, { useEffect, useState } from "react";
import { AiOutlineUser } from "react-icons/ai";
import "./Studenti.css";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Autocomplete from "@mui/material/Autocomplete";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { CssTextField } from "../Login";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel"; // Add this line
import { useSession } from "../../common-backend/SessionContext";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  height: "auto",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 3,
  border: "none",
  outline: "none",
  borderRadius: "10px",
};

export default function Studenti() {
  const { session } = useSession();
  const [selectedUser, setSelectedUser] = React.useState(null);
  const [selectedGroup, setSelectedGroup] = React.useState("");
  const [mesajAdmin, setMesajAdmin] = React.useState("");
  const [payment, setPayment] = React.useState("");
  const [docs, setDocs] = useState([]);
  const [isSwitchOn, setSwitch] = useState(false);
  const [isSwitchVisible, setSwitchVisible] = useState(false);

  const handleUserChange = (event) => {
    setSelectedUser(event.target.value);
  };

  const handleGroupChange = (event) => {
    setSelectedGroup(event.target.value);
  };

  const fillPrezentData = (name) => {
    // Find the user object where the user_name matches the name parameter
    const user = usersInformation.find((user) => user.user_name === name);

    const fetchAppointments = async () => {
      fetch(
        `https://www.autojuv.magnusbooking.ro/api/getAppointments?userId=${user.id}&docs=${true}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${session.session.session.access_token}`,
          },
        }
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.error) {
            console.log(data.error.message);
          }
          // setListaAppointments(data.appointments);
          setMesajAdmin(data.docs?.[0]?.admin_message || "");
          setPayment(data.docs?.[0]?.payment || "");
          setSelectedGroup(data.docs?.[0]?.group || "");
          setSwitch(data.docs?.[0]?.isUserAllowedToViewQuestionnaires === true);

          setSwitchVisible(true);
          setDocs(Array.isArray(data.docs[0].files) ? data.docs[0].files : []);
        });
    };

    fetchAppointments();
  };

  const [users, setUsers] = useState([]);
  const [usersInformation, setUsersInformation] = useState([]);

  // Modal logic
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [errorPostingData, setErrorPostingData] = React.useState("");

  // Modal logic
  const [errorOpen, setErrorOpen] = React.useState(false);
  const handleErrorOpen = () => setErrorOpen(true);
  const handleErrorClose = () => setErrorOpen(false);

  // Inside Studenti component
  const addDocument = (title, date) => {
    setDocs((prevDocs) => [...prevDocs, { date, title }]);
  };
  const deleteDocument = (index) => {
    setDocs((prevDocs) => prevDocs.filter((doc, i) => i !== index));
  };

  const fetchUsers = async () => {
    fetch("https://www.autojuv.magnusbooking.ro/api/getUsers?student=true", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${session.session.session.access_token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          console.log("Error fetching users: " + data.error.message);
        } else {
          const users = data.users.map((user) => ({
            name: user.user_name,
            user_name: user.email.split("@")[0],
          }));
          setUsers(users);
          setUsersInformation(data.users);
        }
      });
  };

  const clearHooks = () => {
    setPayment("");
    setMesajAdmin("");
    setSelectedGroup("");
    setDocs([]);
    setSelectedUser(null); // Add this line
    setSwitchVisible(false);
  };

  const updateUsers = async (payment, mesajAdmin, selectedGroup, docs, isSwitchOn) => {
    // Check if a user is selected
    if (!selectedUser) {
      setErrorPostingData("Please select a user before saving.");
      handleErrorOpen();
      return;
    }
    // Find the user object where the user_name matches selectedUser
    const user = usersInformation.find((user) => user.user_name === selectedUser.name);

    fetch("https://www.autojuv.magnusbooking.ro/api/updateUser", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${session.session.session.access_token}`,
      },
      body: JSON.stringify({
        payment: payment,
        mesajAdmin: mesajAdmin,
        group: selectedGroup,
        docs: docs,
        userId: user.id,
        isUserAllowedToViewQuestionnaires: isSwitchOn,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          setErrorPostingData(data.error.message);
          console.log("Error fetching users: " + data.error.message);
          handleErrorOpen();
        } else {
          setErrorPostingData(data.message);
          clearHooks();
          handleErrorOpen();
        }
      });
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  return (
    <div className="studenti-container">
      <FormControl fullWidth>
        <Autocomplete
          id="combo-box-demo"
          options={users}
          getOptionLabel={(option) => `${option.name} (${option.user_name})`}
          style={{ width: "auto" }}
          value={selectedUser} // control the value directly
          renderInput={(params) => (
            <TextField {...params} label="Selecteaza cursant" variant="outlined" />
          )}
          renderOption={(props, option) => (
            <li {...props}>
              {option.name}&nbsp;
              <span style={{ fontSize: "0.8em" }}>{`(${option.user_name})`}</span>
            </li>
          )}
          onChange={(event, newValue) => {
            setSelectedUser(newValue);
            if (newValue && newValue.name) {
              fillPrezentData(newValue.name);
            } else {
              clearHooks();
            }
          }}
        />
      </FormControl>

      <TextField
        sx={{
          width: "100%",
          marginTop: "30px",
        }}
        id="outlined-basic"
        label="Rest de plata"
        value={payment}
        onChange={(event) => setPayment(event.target.value)}
        variant="outlined"
      />
      <TextField
        sx={{
          width: "100%",
          marginTop: "30px",
        }}
        id="outlined-basic"
        label="Mesaj"
        value={mesajAdmin}
        onChange={(event) => setMesajAdmin(event.target.value)}
        variant="outlined"
      />
      <TextField
        sx={{
          width: "100%",
          marginTop: "30px",
        }}
        id="outlined-basic"
        label="Grupa"
        value={selectedGroup}
        onChange={(event) => setSelectedGroup(event.target.value)}
        variant="outlined"
      />
      {isSwitchVisible && (
        <FormControlLabel
          control={
            <Switch
              checked={isSwitchOn}
              onChange={(event) => setSwitch(event.target.checked)}
              name="checkedSwitch"
              color="primary"
            />
          }
          label="Permite studentului să vadă chestionarele"
        />
      )}

      <div className="studenti-container-docs">
        <h4>Sterge documente:</h4>
        {docs?.map((el, index) => {
          return (
            <div className="studenti-container-docs-items" key={index}>
              <p>
                <b>{el.title}</b> expira la:{" "}
              </p>
              <TextField
                sx={{
                  width: "200px",
                  marginLeft: "30px",
                }}
                id="outlined-basic"
                label="Expira la"
                variant="outlined"
                value={el.date}
                onChange={(event) => {
                  const newDocs = [...docs];
                  newDocs[index].date = event.target.value;
                  setDocs(newDocs);
                }}
              />
              <img src="/media/icons/delete.png" onClick={() => deleteDocument(index)} />
            </div>
          );
        })}
        <div className="vezi-profesori-left-top">
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <ModalDocumente addDocument={addDocument} handleClose={handleClose} />
            </Box>
          </Modal>
          <img title="Adauga profesor" src="/media/icons/button.png" onClick={handleOpen} />
          <p>Adaugă documente</p>
        </div>
      </div>
      <Button
        style={{
          backgroundColor: "var(--main)",
          padding: "18px 36px",
          fontSize: "18px",
          marginTop: "30px",
        }}
        variant="contained"
        onClick={() => {
          updateUsers(payment, mesajAdmin, selectedGroup, docs, isSwitchOn);
        }}
      >
        Salveaza
      </Button>
      <Modal
        open={errorOpen}
        onClose={handleErrorClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <ModalConfirmare handleClose={handleErrorClose} errorPostingData={errorPostingData} />
        </Box>
      </Modal>
    </div>
  );
}

const ModalDocumente = ({ addDocument, handleClose }) => {
  // Inside ModalDocumente component
  const [docTitle, setDocTitle] = React.useState("");
  const [docDate, setDocDate] = React.useState("");

  const handleAddDocument = () => {
    addDocument(docTitle, docDate);
    setDocDate("");
    setDocTitle("");
    handleClose();
  };

  return (
    <div className="vezi-profesori-left-modal">
      <h3>Adauga document</h3>
      <div className="vezi-profesori-left-modal-row">
        <CssTextField
          label="Nume document"
          onChange={(e) => setDocTitle(e.target.value)}
          sx={{
            width: "100%",
          }}
        />
      </div>
      <div className="vezi-profesori-left-modal-row">
        <CssTextField
          label="Valabil pana la"
          onChange={(e) => setDocDate(e.target.value)}
          sx={{
            width: "100%",
          }}
        />
      </div>
      <div className="vezi-profesori-left-modal-row-btn ">
        <Button
          style={{
            backgroundColor: "var(--main)",
            padding: "18px 36px",
            fontSize: "18px",
            marginRight: "20px",
          }}
          variant="contained"
          onClick={handleAddDocument}
        >
          Adauga
        </Button>
      </div>
    </div>
  );
};

const ModalConfirmare = ({ handleClose, errorPostingData }) => {
  return (
    <div className="vezi-profesori-left-modal">
      <p
        style={{
          fontSize: "18px",
          marginBottom: "20px",
          textAlign: "center",
        }}
      >
        {errorPostingData}
      </p>
      <div className="vezi-profesori-left-modal-row-btn ">
        <Button
          style={{
            backgroundColor: "var(--main)",
            padding: "18px 36px",
            fontSize: "18px",
            marginRight: "20px",
          }}
          variant="contained"
          onClick={handleClose}
        >
          Inchide
        </Button>
      </div>
    </div>
  );
};
