import React, { useRef, useState, useEffect } from "react";
import "../pages/Administrator/Chestionar.css";
import ReactToPrint from "react-to-print";
import { useSession } from "../common-backend/SessionContext";

export default function ResultsPageChestionare({
  score,
  quizData,
  answers,
  handleBackButtonClick,
  userName,
  nameChestionar,
  chestionarDate,
}) {
 const componentRef = useRef();

  const canvasRef = useRef(null);
  const [isDrawing, setIsDrawing] = useState(false);
  const [context, setContext] = useState(null);

  // Set up the canvas context
  useEffect(() => {
    const canvas = canvasRef.current;
    canvas.width = canvas.offsetWidth;
    canvas.height = canvas.offsetHeight;
    const ctx = canvas.getContext("2d");
    ctx.strokeStyle = "black";
    ctx.lineWidth = 2;
    ctx.lineCap = "round";
    setContext(ctx);
  }, []);

  // Start drawing
  const startDrawing = (event) => {
    setIsDrawing(true);
    const { offsetX, offsetY } = getEventCoordinates(event);
    context.beginPath();
    context.moveTo(offsetX, offsetY);
  };

  // Draw on the canvas
  const draw = (event) => {
    if (!isDrawing) return;
    const { offsetX, offsetY } = getEventCoordinates(event);
    context.lineTo(offsetX, offsetY);
    context.stroke();
  };

  // Stop drawing
  const stopDrawing = () => {
    setIsDrawing(false);
    context.closePath();
  };

  // Get coordinates for mouse/touch events
  const getEventCoordinates = (event) => {
    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();

    if (event.touches) {
      const touch = event.touches[0];
      return {
        offsetX: touch.clientX - rect.left,
        offsetY: touch.clientY - rect.top,
      };
    }

    return {
      offsetX: event.nativeEvent.offsetX,
      offsetY: event.nativeEvent.offsetY,
    };
  };

  // Clear the canvas
  const clearCanvas = () => {
    context.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
  };

  // Save the signature
  const saveSignature = () => {
    const dataURL = canvasRef.current.toDataURL("image/png");
    console.log("Signature Data URL:", dataURL);
  };


  const { session } = useSession();
  return (
    <>
      <div className="rezultat-chestionar-container" ref={componentRef}>
        <div className="rezultat-chestionar-container-header">
          <div className="rezultat-chestionar-container-header-item">
            <button onClick={handleBackButtonClick}>Ieși din chestionar</button>
            <img src="/media/icons/exit.png" />
          </div>
          <div className="rezultat-chestionar-container-header-item">
            <ReactToPrint
              trigger={() => <button>Printează!</button>}
              content={() => componentRef.current}
            />
            <img src="/media/icons/printer.png" />
          </div>
        </div>
        {(score / quizData.questions.length) * 100 >= 91.67 ? (
          <div style={{ fontSize: "20px", display: "flex", justifyContent: "space-between", alignItems: "center" }} className="signBox">
            <div style={{ justifyContent: 'center' }}>
              <span style={{ color: "green", fontSize: "40px", fontWeight: "bold" }}>Admis</span>
              <br></br>
              punctaj: {score}/{quizData.questions.length}
              <br></br>
              Cursant: {session.userInfo.user_name}
              <br></br>
              {nameChestionar} din data {chestionarDate}
            </div>
            <div style={{ textAlign: "center", marginTop: "20px" }}>
              <div
                style={{
                  border: "2px solid #ccc",
                  borderRadius: "5px",
                  width: "300px",
                  height: "150px",
                  position: "relative",
                }}
              >
                <canvas
                  ref={canvasRef}
                  style={{ width: "100%", height: "100%", touchAction: "none" }}
                  onMouseDown={startDrawing}
                  onMouseMove={draw}
                  onMouseUp={stopDrawing}
                  onMouseLeave={stopDrawing}
                  onTouchStart={startDrawing}
                  onTouchMove={draw}
                  onTouchEnd={stopDrawing}
                ></canvas>
              </div>
              <div style={{ marginTop: "10px" }}>
                <button onClick={clearCanvas} style={{ marginRight: "10px", padding: 5 }}>
                  Șterge
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div style={{ fontSize: "20px", display: "flex", justifyContent: "space-between", alignItems: "center" }} className="signBox">
            <div style={{ justifyContent: 'center', alignItems: "center", alignContent: 'center', textAlign: 'center' }}>
              <span style={{ color: "red", fontSize: "40px", fontWeight: "bold" }}>Respins</span>
              <br></br>
              punctaj: {score}/{quizData.questions.length}
              <br></br>
              punctaj: {score}/{quizData.questions.length}
              <br></br>
              Cursant: {session.userInfo.user_name}
              <br></br>
              {nameChestionar} din data {chestionarDate}
            </div>
              <div style={{ textAlign: "center", marginTop: "20px" }}>
                <div
                  style={{
                    border: "2px solid #ccc",
                    borderRadius: "5px",
                    width: "300px",
                    height: "150px",
                    position: "relative",
                  }}
                >
                  <canvas
                    ref={canvasRef}
                    style={{ width: "100%", height: "100%", touchAction: "none" }}
                    onMouseDown={startDrawing}
                    onMouseMove={draw}
                    onMouseUp={stopDrawing}
                    onMouseLeave={stopDrawing}
                    onTouchStart={startDrawing}
                    onTouchMove={draw}
                    onTouchEnd={stopDrawing}
                  ></canvas>
                </div>
                <div style={{ marginTop: "10px" }}>
                  <button onClick={clearCanvas} style={{ marginRight: "10px", padding: 5 }}>
                    Șterge
                  </button>
                </div>
              </div>
          </div>
        )}
        {answers.map((answer, index) => {
          return <RezultatChestionarItem answer={answer} index={index} key={index} />;
        })}
      </div>
    </>
  );
}

const RezultatChestionarItem = ({ answer, index }) => {
  console.log("A:", answer);
  console.log("B:", index);

  const indexAlphabet = ["A", "B", "C", "D", "E", "F", "G", "H", "I"];

  const decidecCuloareRasp = (option, optionIndex) => {
    const isOptionSelected = answer.userAnswers.some(
      (userAnswer) => userAnswer.index === optionIndex
    );
    if (option.isCorrect) {
      if (isOptionSelected) return "green";
      return "lightgreen";
    } else {
      if (isOptionSelected) return "red";
      return "";
    }
  };

  const displaySupposedAnswersAsHtml = (answer) => {
    console.log("ANSWER:", answer);

    const chosenAnswers = answer.userAnswers.map((userAnswer) => {
      return indexAlphabet[userAnswer.index];
    }).join(", ");

    const correctAnswers = answer.question.answerOptions
      .map((option, index) => option.isCorrect ? indexAlphabet[index] : null)
      .filter(option => option !== null)
      .join(", ");

    return `Răspuns ales: ${chosenAnswers || 'niciunul'}   Răspuns corect: ${correctAnswers}`;
  };

  return (
    <>
      <div
        style={{
          backgroundColor: "rgb(241, 241, 241)",
          marginBottom: "30px",
          marginTop: "30px",
          height: "auto",
          borderRadius: "10px",
          padding: "30px",
          breakInside: "avoid", // Prevents the element from being split across pages
        }}
        className="chestionar-container"
      >
        <div className="rezultat-chestionar-container-header">
          <p
            style={{
              color: `${answer.isCorrect ? "green" : "red"}`,
              fontSize: "1.3rem",
            }}
          >
            Raspuns: <span>{answer.isCorrect ? "Corect" : "Greșit"}</span>
          </p>
          <p >
            <span className="finalResults">
              {displaySupposedAnswersAsHtml(answer)}
            </span>
          </p>
        </div>
        <div className="chestionar-container-content">
          <div
            className="chestionar-container-content-top"
            style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
          >
            <p>
              Întrebarea {index + 1}: {answer.question.questionText}
            </p>
            {answer.question.questionImage && (
              <img src={answer.question.questionImage} alt="question" style={{ width: "30%" }} className="imageQuestion" />
            )}
          </div>
          <div className="chestionar-container-content-values">
            <div
              className="chestionar-container-content-values-ques chestionar-container-content-values-ques-answers"
              style={{
                display: "flex",
                flexWrap: "wrap",
                flexDirection: "column",
                flexDirection: `${answer.question.answerOptions[0].answerImage !== undefined ? "row" : "column"
                  }`,
              }}
            >
              {answer.question.answerOptions.map((option, optionIndex) => {
                return (
                  <p
                    key={optionIndex}
                    style={{
                      backgroundColor: `${decidecCuloareRasp(option, optionIndex)}`,
                      marginBottom: `${optionIndex === answer.question.answerOptions.length - 1 ? "0px" : "30px"
                        }`,
                      width: `${option.answerImage !== undefined ? "50%" : "100%"}`,
                      minWidth: "300px",
                      maxWidth: `${option.answerImage !== undefined ? "30%" : "100%"}`,
                      margin: "10px",
                      breakInside: "avoid", // Prevents the element from being split across pages
                    }}
                  >
                    <span>{indexAlphabet[optionIndex]}</span> {option.answerText}
                    <div className="chestionar-container-content-values-ques-image">
                      {option.answerImage && (
                        <img src={option.answerImage} alt="answer" style={{ marginLeft: "10px" }} />
                      )}
                    </div>
                  </p>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
